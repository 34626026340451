import constants from "../Types/actionTypes";

const initialState = {
  heatMapDetail: [],
  HomeData: {},
  BillBoardData: {},
  UserData: {},
  billBoardData: {},
  offlinebillBoardData: {},
  cameraBillBoard: [],
  loginData: {},
  billBoardDetail: {},
  ticketsList: {},
  allTicketCount: {},
  selectedTicket: {},
  ticket: {},
  chats: {},
  lastTextDate: {},
  AdDetails: [],
  CalenderDate: {},
  allNotifications:{},
  mobileViewTicketToggle:false
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.heatMapDetail:
      return {
        ...state,
        heatMapDetail: action.data,
      };
    case constants.HomeData:
      return {
        ...state,
        HomeData: action.data,
      };
    case constants.BillBoardData:
      return {
        ...state,
        BillBoardData: action.data,
      };
    case constants.UserData:
      return {
        ...state,
        UserData: action.data,
      };
    case constants.billBoardData:
      return {
        ...state,
        billBoardData: action.data,
      };
    case constants.offlinebillBoardData:
      return {
        ...state,
        offlinebillBoardData: action.data,
      };
    case constants.cameraBillBoard:
      return {
        ...state,
        cameraBillBoard: action.data,
      };
    case constants.loginData:
      return {
        ...state,
        loginData: action.data,
      };
    case constants.billBoardDetail:
      return {
        ...state,
        billBoardDetail: action.data,
      };
    case constants.AdDetails:
      return {
        ...state,
        AdDetails: action.data,
      };
    case constants.CalenderDate:
      return {
        ...state,
        CalenderDate: action.data,
      };

    case constants.ticketsList:
      return {
        ...state,
        ticketsList: action.data,
      };
    case constants.allTicketCount:
      return {
        ...state,
        allTicketCount: action.data,
      };
    case constants.selectedTicket:
      return {
        ...state,
        selectedTicket: action.data,
      };
    case constants.ticket:
      return {
        ...state,
        ticket: action.data,
      };
    case constants.chats:
      return {
        ...state,
        chats: action.data,
      };
    case constants.lastTextDate:
      return {
        ...state,
        lastTextDate: action.data,
      };
    case constants.allNotifications:
      return {
        ...state,
        allNotifications: action.data,
      };
    case constants.mobileViewTicketToggle:
      return {
        ...state,
        mobileViewTicketToggle: action.data,
      };
    default:
      return state;
  }
};

export default rootReducer;
