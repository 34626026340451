const constants = {
  AdDetails: "ADD_DETAILS",
  CalenderDate: "CalenderDate",
  heatMapDetail: "HETA-MAP-DETAILS",
  HomeData: "HOME_DATA",
  BillBoardData: "BILL_BOARD_DATA",
  UserData: "USER_DATA",
  billBoardData: "BILL_BOARD",
  offlinebillBoardData: "OFFLINE_BILLBOARD_DATA",
  cameraBillBoard: "CAMERA_BILL_BOARD",
  loginData: "LOGIN_DATA",
  billBoardDetail: "BILL_BOARD_DETAIL",
  ticketsList: "TICKET_LIST",
  allTicketCount: "ALL_TICKET_COUNT",
  selectedTicket: "SELECTED_TICKET",
  ticket: "TICKET",
  chats: "CHATS",
  lastTextDate: "LAST_TEXT_DATE",
  allNotifications: "ALL_NOTIFICAITONS",
  mobileViewTicketToggle:"MOBILE_VIEW_TOGGLE",
};
export default constants;
