/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { SVG } from "../../../icon/svg.file";
import { Link, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { isIntegerValue } from "../../../utility-files/data-util/DataHandler";
import {
  getDateFormat,
  getDateWithTimeFormat,
} from "../../../utility-files/date-util/DateHandling";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import * as global from "../../../constant/global";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import GoogleMapComponent from "../../dashboard/viewMap";
import action from "../../../new redux/Action";
import constants from "../../../new redux/Types/actionTypes";
import MapView from "./mapView";
import { useTranslation } from "react-i18next";

export default function EditBillboard() {
  const { t } = useTranslation();
  const [billData, setBillData] = useState();
  const [pending, setPending] = useState(false);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const cameraBillBoard = useSelector((state) => state.cameraBillBoard);
  const billBoardDetail = useSelector((state) => state.billBoardDetail);
  const [createData, setCreateData] = useState({ remark: "" });
  const [markerPosition, setMarkerPosition] = useState(null); // State to hold the marker's position
  const params = useParams();
  const Name = params?.billboardName;
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const markerRef = useRef(null); // Reference to hold the marker instance
  const dispatch = useDispatch();

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  useEffect(() => {
    const uploadImage = async (formData) => {
      try {
        const response = await axios.post(
          `${global.UPLOAD_IMAGE}${Name}/add-image`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAuthenticated}`, // Ensure token is in Bearer format
            },
          }
        );
        return response.data;
      } catch (error) {
        throw new Error("Error uploading image");
      }
    };

    const handleImageUpload = async () => {
      if (files.length === 0 || !isAuthenticated) {
        return;
      }

      const formData = new FormData();
      formData.append("billboardImage", files[0]);

      try {
        const data = await uploadImage(formData);
        setFiles((prev) => prev.slice(1));
        setImages((prev) => [...prev, data.path.split("/")[2]]);
        toast.success(
          t("Billboard_Edit.BillboardTraffic.messages.imageUploadSuccess")
        );
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    };

    handleImageUpload();
  }, [files, isAuthenticated, Name]);

  const handleFileChange = (event) => {
    const imgData = event.target.files[0];
    setFiles((prev) => [...prev, imgData]);
  };

  const getBoardDetail = async () => {
    setPending(true);
    let requset;
    requset = getRequestForApi(
      global.GET_ALL_BILLBOARD_DETAILS + `${Name}`,
      methodType.GET
    );
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          dispatch(action(constants.billBoardDetail, response?.data));
          setBillData(response?.data);
          setPending(false);
        }
      })
      .catch((err) => {});
    setPending(false);
  };

  useEffect(() => {
    getBoardDetail();
  }, []);

  // useEffect(() => {
  //   if (billData?.data) {
  //     setEditData({
  //       contactName: billData.data.contactName || '',
  //       address: billData.data.address || '',
  //       phone: billData.data.phone || '',
  //       email: billData.data.email || '',
  //     });
  //   }
  // }, [billData]);

  console.log("billData.data.contactName", billData);

  const createRemark = async () => {
    setPending(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("remark", createData?.remark);
      let request = getRequestForApi(
        global.CREATE_REMARK + `${Name}`,
        formDataToSend,
        methodType.POST
      );
      const response = await callHttpRequest(request);
      if (response?.status === 200 || response?.status === 201) {
        toast.success(
          t("Billboard_Edit.BillboardTraffic.messages.remarkSuccess")
        );
        setCreateData();
      } else {
        toast.error(t("Billboard_Edit.BillboardTraffic.messages.error"));
        console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.error("Error occurred:", err);
    } finally {
      setPending(false);
    }
  };

  const handleMapClick = (e) => {
    if (!e || !e.latLng) {
      console.error("Click event or latLng is undefined:", e);
      return;
    }

    const latitude = e.latLng.lat();
    const longitude = e.latLng.lng();

    if (markerRef.current) {
      markerRef.current.setMap(null);
    }

    const newMarker = new window.google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: mapInstance.current,
    });

    markerRef.current = newMarker;

    setMarkerPosition({ lat: latitude, lng: longitude });
  };

  const initMap = () => {
    if (!mapRef.current) {
      console.error("Map container ref is undefined");
      return;
    }

    mapInstance.current = new window.google.maps.Map(mapRef.current, {
      center: { lat: 51.505, lng: -0.09 },
      zoom: 13,
    });

    mapInstance.current.addListener("click", handleMapClick);
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      initMap();
    } else {
      console.error("Google Maps JavaScript API not loaded");
    }
  }, []);

  return (
    <>
      {pending && <CustomLoader />}
      <Stack
        direction={{ xs: "column", lg: "row" }}
        spacing={2}
        alignItems={{ xs: "self-start", lg: "center" }}
        justifyContent="space-between"
        sx={{ mb: 3 }}
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: "30px",
            color: "#fff",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            "@media(max-width:992px)": {
              fontSize: "24px",
            },
          }}
        >
          <IconButton LinkComponent={Link} to={`/billboard/details/${Name}`}>
            <SVG.ArrowRight />
          </IconButton>{" "}
          <span> {t("Billboard_Edit.BillboardTraffic.title")}</span>
        </Typography>
      </Stack>
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <TextField
          value={params?.billboardName}
          placeholder="Billboard Name"
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          sx={{
            "& .MuiInputBase-root": {
              background: "transparent !important",
              borderRadius: "10px",
              color: "#fff",
              input: {
                "&::placeholder": {
                  color: "#fff",
                  opacity: 1,
                },
              },
            },
          }}
        />
      </Stack>
      <Box sx={{ mt: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Card sx={{ borderRadius: "10px" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,

                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t(
                    "Billboard_Edit.BillboardTraffic.labels.billboardLocation"
                  )}
                </Typography>
                <MapView />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ borderRadius: "10px", height: "100%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t("Billboard_Edit.BillboardTraffic.labels.contactDetails")}
                </Typography>
                <Stack direction={"column"} spacing={2}>
                  <Box
                    sx={{
                      "& label": {
                        mb: 1,
                        fontSize: "12px",
                        display: "inline-block",
                      },
                    }}
                  >
                    <label>
                      {t(
                        "Billboard_Edit.BillboardTraffic.placeholder.contactName"
                      )}
                    </label>
                    <TextField
                      size="small"
                      value={billBoardDetail?.data?.contactName}
                      placeholder={t(
                        "Billboard_Edit.BillboardTraffic.placeholder.contactName"
                      )}
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "transparent !important",
                          borderRadius: "10px",
                          border: "1px solid #687588",
                          color: "#fff",
                          input: {
                            "&::placeholder": {
                              color: "#fff",
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        mb: 1,
                        fontSize: "12px",
                        display: "inline-block",
                      },
                    }}
                  >
                    <label>
                      {t("Billboard_Edit.BillboardTraffic.placeholder.address")}
                    </label>
                    <TextField
                      size="small"
                      value={billBoardDetail?.data?.address}
                      placeholder={t(
                        "Billboard_Edit.BillboardTraffic.placeholder.address"
                      )}
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "transparent !important",
                          borderRadius: "10px",
                          border: "1px solid #687588",
                          color: "#fff",
                          input: {
                            "&::placeholder": {
                              color: "#fff",
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        mb: 1,
                        fontSize: "12px",
                        display: "inline-block",
                      },
                    }}
                  >
                    <label>
                      {t(
                        "Billboard_Edit.BillboardTraffic.placeholder.phoneNumber"
                      )}
                    </label>
                    <TextField
                      size="small"
                      value={billBoardDetail?.data?.phone}
                      placeholder="1800-000-000"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "transparent !important",
                          borderRadius: "10px",
                          border: "1px solid #687588",
                          color: "#fff",
                          input: {
                            "&::placeholder": {
                              color: "#fff",
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        mb: 1,
                        fontSize: "12px",
                        display: "inline-block",
                      },
                    }}
                  >
                    <label>
                      {t("Billboard_Edit.BillboardTraffic.placeholder.email")}
                    </label>
                    <TextField
                      size="small"
                      value={billBoardDetail?.data?.email}
                      placeholder={t(
                        "Billboard_Edit.BillboardTraffic.placeholder.email"
                      )}
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "transparent !important",
                          borderRadius: "10px",
                          border: "1px solid #687588",
                          color: "#fff",
                          input: {
                            "&::placeholder": {
                              color: "#fff",
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ borderRadius: "10px", height: "100%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 5.5,
                  }}
                >
                  {t("Billboard_Edit.BillboardTraffic.labels.remarks")}
                </Typography>
                <Box
                  sx={{
                    border: "2px dashed #D8DAE180",
                    background: "#282520",
                    outline: "none",
                    height: "306px",
                    width: "100%",
                    borderRadius: "5px",
                    resize: "none",
                    p: 2,
                    color: "#fff", // To make sure text color is visible
                    display: "block",
                    fontSize: "14px", // Adjust font size as needed
                  }}
                  component="textarea"
                  placeholder={t(
                    "Billboard_Edit.BillboardTraffic.placeholder.remark"
                  )}
                  value={createData?.remark || ""}
                  onChange={(e) =>
                    setCreateData({
                      ...createData,
                      remark: e.target.value,
                    })
                  }
                />
                <Box sx={{ textAlign: "center", mt: 2 }}>
                  <Button
                    sx={{
                      width: "150px",
                      background: "#fff",
                      color: "#160B01",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      "&:hover": {
                        background: "#fff",
                        color: "#160B01",
                      },
                    }}
                    size="large"
                    onClick={() => {
                      createRemark();
                      setCreateData();
                    }}
                  >
                    {t("Billboard_Edit.BillboardTraffic.buttons.save")}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={5}>
            <Card sx={{ borderRadius: "10px", height: "100%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t(
                    "Billboard_Edit.BillboardTraffic.labels.uploadGalleryImages"
                  )}
                </Typography>
                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "2px dashed #C4C4C4",
                    background: "#D8DEE933",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "230px",
                    "& input": {
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      opacity: 0,
                      cursor: "pointer",
                    },
                  }}
                >
                  <input type="file" onChange={handleFileChange} />
                  <SVG.UploadUp />
                  <Box
                    sx={{
                      mt: 1,
                      color: "#FDBF2D",
                      borderBottom: "1px solid #FDBF2D",
                    }}
                  >
                    {t(
                      "Billboard_Edit.BillboardTraffic.placeholder.uploadFiles"
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: 3.5 }}>
                  <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                    {images.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "138px",
                          height: "125px",
                          borderRadius: "5px",
                          position: "relative",
                          "@media (max-width:1440px)": {
                            width: "116px",
                          },
                        }}
                      >
                        <IconButton
                          size="small"
                          sx={{
                            background: "#DF9808",
                            color: "#fff",
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            "&:hover": {
                              background: "#DF9808",
                              color: "#fff",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{
                              fontSize: "14px",
                            }}
                          />
                        </IconButton>
                        <img
                          src={`${global.BASE_URL}/media/billboards/${data}`}
                          alt=""
                          style={{
                            width: "100%",
                            height: "125px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Card sx={{ borderRadius: "10px", height: "100%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "15px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t(
                    "Billboard_Edit.BillboardTraffic.labels.advertisingContent"
                  )}
                </Typography>
                <Stack direction={"column"} spacing={2}>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      sx={{
                        width: "190px",

                        background: "#151412",
                        fontSize: "13px",
                        fontWeight: "600",
                        p: 2,
                        borderRadius:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0px 8px 8px 0px"
                            : "8px 0px 0px 8px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {t(
                        "Billboard_Edit.BillboardTraffic.labels.amountAdsDisplayed"
                      )}{" "}
                    </Box>
                    <Box
                      sx={{
                        background: "#FFFFFF1A",
                        p: 2,
                        flex: 1,
                        fontSize: "13px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      34
                    </Box>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      sx={{
                        width: "190px",

                        background: "#151412",
                        fontSize: "13px",
                        fontWeight: "600",
                        p: 2,
                        borderRadius:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0px 8px 8px 0px"
                            : "8px 0px 0px 8px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {t(
                        "Billboard_Edit.BillboardTraffic.labels.spotsPerAdPerDay"
                      )}
                    </Box>
                    <Box
                      sx={{
                        background: "#FFFFFF1A",
                        p: 2,
                        flex: 1,
                        fontSize: "13px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {isIntegerValue(billData?.data?.spots_per_ad_per_day)}
                    </Box>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      sx={{
                        width: "190px",

                        background: "#151412",
                        fontSize: "13px",
                        fontWeight: "600",
                        borderRadius:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0px 8px 8px 0px"
                            : "8px 0px 0px 8px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                        p: 2,
                      }}
                    >
                      {t(
                        "Billboard_Edit.BillboardTraffic.labels.installationDate"
                      )}
                    </Box>
                    <Box
                      sx={{
                        background: "#FFFFFF1A",
                        p: 2,
                        flex: 1,
                        fontSize: "13px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {getDateFormat(billData?.data?.installationDate)}
                    </Box>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      sx={{
                        width: "190px",
                        borderRadius:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0px 8px 8px 0px"
                            : "8px 0px 0px 8px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                        background: "#151412",
                        fontSize: "13px",
                        fontWeight: "600",
                        p: 2,
                      }}
                    >
                      {t("Billboard_Edit.BillboardTraffic.labels.nextPayment")}
                    </Box>
                    <Box
                      sx={{
                        background: "#FFFFFF1A",
                        p: 2,
                        flex: 1,
                        fontSize: "13px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {billData?.data?.nextPaymentDate
                        ? getDateFormat(billData?.data?.nextPaymentDate)
                        : "NA"}
                    </Box>
                  </Stack>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Box
                      sx={{
                        width: "190px",

                        background: "#151412",
                        fontSize: "13px",
                        fontWeight: "600",
                        p: 2,
                        borderRadius:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "0px 8px 8px 0px"
                            : "8px 0px 0px 8px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {t(
                        "Billboard_Edit.BillboardTraffic.labels.monthlyPayment"
                      )}
                    </Box>
                    <Box
                      sx={{
                        background: "#FFFFFF1A",
                        p: 2,
                        flex: 1,
                        fontSize: "13px",
                        textAlign:
                          localStorage.getItem("i18nextLng") === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      {billData?.data?.billboardRent
                        ? billData?.data?.billboardRent
                        : 0}
                    </Box>
                  </Stack>
                  {/* <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Button
                      sx={{
                        width: "150px",
                        background: "#fff",
                        color: "#160B01",
                        fontSize: "16px",
                        height: "56px",
                        borderRadius: "10px",
                        textTransform: "capitalize",
                        "&:hover": {
                          background: "#fff",
                          color: "#160B01",
                        },
                      }}
                      size="large"
                    >
                      Save
                    </Button>
                  </Box> */}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
