// TicketMessage.js
import {
  Box,
  Chip,
  CircularProgress,
  IconButton,
  LinearProgress,
  Stack,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import axios from "axios";
import { toast } from "react-toastify";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import {
  SocketContext,
  useSocket,
} from "../../utility-files/socket/socket.provider"; // Adjust the path based on your file structure
import { SVG } from "../../icon/svg.file";
import moment from "moment";
import * as global from "../../constant/global";
import {
  ArrowCircleDown,
  AttachFile,
  Close,
  OpenInFull,
} from "@mui/icons-material";
import ImagePreviewModal from "../ticket/image-preview-modal";
import VideoPreviewModal from "./video-preview-modal";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { FidgetSpinner } from "react-loader-spinner";
import ImagePreview from "./image-preview";
import VideoPreview from "./video-preview";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const CONN_PORT = "ws://localhost:1641"; // Update as needed

export default function TicketMessage({ loading }) {
  const [chats, setChats] = useState({
    isLoading: false,
    data: null,
    hasMore: true,
  });
  const matches = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const ticket = useSelector((state) => state.ticket);
  const selectedTicket = useSelector((state) => state.selectedTicket);
  const [preview, setPreview] = useState("");
  const [previewVideo, setPreviewVideo] = useState("");
  // const chats = useSelector((state) => state.chats);
  const [isTextarea, setIsTextArea] = useState(false);
  const [lastTextDate, setLastTextDate] = useState("");
  const [newMsg, setNewMsg] = useState("");
  const [chatMessage, setChatMessage] = useState({
    room: "",
    message: "",
  });
  const [sendFile, setSendFile] = useState({
    file: undefined,
    sending: false,
  });
  const [query, setQuery] = useSearchParams();
  const ticketId = query.get("ticket");
  const socket = useSocket();
  const dispatch = useDispatch();
  const { setIsUnreadMsgs } = useContext(SocketContext);

  const messagesEndRef = useRef(null);
   const inputRef = useRef(null); // Reference for the input field
  const userData = useSelector((state) => state.UserData);

  // console.log("Ticket Messgae Socket", socket.connected);

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const handleArea = () => {
    setIsTextArea(!isTextarea);
  };

  const handleSendMessage = () => {
    if (!sendFile.file) {
      const room = {
        room: chatMessage.room,
        message: chatMessage.message,
      };
      // console.log("<semd>room", room);
      socket?.emit("sendMessage", room); // Use socket.io emit for sending messages
      scrollToBottom(); // Scroll to bottom after sending text
    } else {
      const formData = new FormData();
      formData.append("room", chatMessage.room);
      formData.append("file", sendFile.file);

      setSendFile((prev) => ({
        ...prev,
        sending: true,
      }));

      const fileSize = Math.round(sendFile.file.size / 1024);
      if (fileSize <= 15360) {
        axios
          .post(global.GET_CHAT_LIST, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then(({ data }) => {
            setSendFile({
              file: undefined,
              sending: false,
            });

            const room = {
              room: chatMessage.room,
              message: data.chat.message,
              type: "url",
            };

            socket?.emit("sendMessage", room); // Use socket.io emit for sending messages
            scrollToBottom(); // Scroll to bottom after sending text
          })
          .catch(() => {
            toast.error(t("Ticket_message.messages.uploadFail"));
            setSendFile({
              file: undefined,
              sending: false,
            });
          });
      } else {
        toast.error(t("Ticket_message.messages.sizeExceed"));
        setSendFile({
          file: undefined,
          sending: false,
        });
      }
    }
    setChatMessage({ ...chatMessage, message: "" });

    if(inputRef.current){
       inputRef.current.focus();
    }
  };

  useEffect(() => {
    setChatMessage({
      room: ticket,
      message: "",
    });
  }, [ticket]);

  //  const sendMessage = (id, message) => {
  //    socket.emit("sendMessage", {
  //      room: id,
  //      message: message,
  //    });
  //  };

  const enterKeySendText = (key) => {
    if (key.key === "Enter" && chatMessage.message.trim().length) {
      handleSendMessage();
      key.preventDefault(); // Prevent the default action to avoid new line in input
    }
  };

  function convertMsgToLinks(msg) {
    const phonePattern = /(\+\d{1,3})?(\d{10,12})/g;
    const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g;
    const urlPattern =
      /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/g;

    msg = msg.replace(emailPattern, function (match) {
      return `<a href="mailto:${match}">${match}</a>`;
    });

    msg = msg.replace(phonePattern, function (match) {
      return `<a href="tel:${match}">${match}</a>`;
    });

    msg = msg.replace(urlPattern, function (match) {
      return `<a target="_blank" href="${match}">${match}</a>`;
    });

    return msg;
  }

  /**
   *
   *  Checking for acceptable image and video urls
   */
  const isImageURL = (url) => {
    const acceptedImageFormats = [".jpeg", ".jpg", ".png", ".gif", ".jpg"];
    const extension = url.split(".").pop();
    return acceptedImageFormats.includes(`.${extension}`);
  };

  const isVideoURL = (url) => {
    const acceptedVideoFormats = [".mp4", ".mkv", ".mov", ".wmv", ".flv"];
    const extension = url.split(".").pop();
    return acceptedVideoFormats.includes(`.${extension}`);
  };

  const getAllChats = async (date) => {
    // console.log(
    //   `<TicketMessage> date -- ${date},lastTextDate -- ${lastTextDate},${
    //     date !== ""
    //   }`
    // );
    if (!ticket) return;
    setChats((prev) => ({ ...prev, isLoading: true }));
    // dispatch(action(constants.chats, { ...chats, isLoading: true }));

    const dateFilter = date !== "" ? lastTextDate : date;
    let request;
    request = getRequestForApi(
      global.GET_CHAT_LIST + `/${ticket}?limit=10&date=${dateFilter || ""}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then(({ data }) => {
        dispatch(
          action(constants.chats, {
            data: data.data,
            isLoading: false,
            hasMore: data.results !== 0,
          })
        );
        setChats((prev) => ({
          data: [...(prev.data || []), ...data.data],
          isLoading: false,
          hasMore: data.results !== 0,
        }));
        setLastTextDate(data.data[data.data.length - 1].createdAt);
        dispatch(
          action(
            constants.lastTextDate,
            data.data[data.data.length - 1].createdAt
          )
        );
      })
      .catch(() => {
        // Handle error
        setChats((prev) => ({
          data: prev.data,
          isLoading: false,
          hasMore: false,
        }));
        // dispatch(
        //   action(constants.chats, {
        //     data: chats.data.reverse(),
        //     isLoading: false,
        //     hasMore: false,
        //   })
        // );
      });
  };

  useEffect(() => {
    if (selectedTicket?.data?.ticket?._id) {
      setChats({
        data: null,
        isLoading: false,
      });
      setLastTextDate("");
      getAllChats("");
    }
  }, [selectedTicket?.data?.ticket?._id]);

  const receiveListener = (msg) => {
    // console.log("<receiveListener>", msg);
    setNewMsg(msg);
    try {
      const ad = new Audio("/beep.mp3");
      ad.play();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!socket) return;
    if (!socket.connected) socket.connect();

    socket.on("receiveMessage", receiveListener);
    // socket.on("receiveMessage", (msg) => console.log("mmmmmmm", msg));
    return () => {
      socket.off("receiveMessage", receiveListener);
    };
  }, []);

  useEffect(() => {
    setIsUnreadMsgs((prevMsg) => {
      return prevMsg.filter((item) => item != ticketId);
    });
  }, [ticketId]);

  useEffect(() => {
    if (!newMsg) return;

    if (ticket === newMsg?.room) {
      console.log("<TicketMessage> mark");

      socket?.emit("mark", ticket);
      // dispatch(
      //   action(constants?.chats, {
      //     ...chats,
      //     data: [newMsg, ...(chats?.data?.reverse() || [])].reverse(),
      //   })
      // );
      setChats((prev) => ({
        ...prev,
        data: [newMsg, ...(prev.data || [])],
      }));
    }
  }, [newMsg, ticket]);

  const scrollToBottom = () => {
   if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // useEffect(() => {
  //   // Scroll to the last message when chats change
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [chats, loading]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 395px)",
        "@media(max-width:992px)": {
          height: "calc(100vh - 303px)",
        },
      }}
    >
      {loading ? (
        <FidgetSpinner />
      ) : (
        <Box
          sx={{
            flex: "1 1 auto",
            // overflow: "auto",
            // overflowX: "hidden",
            height: "80%",
          }}
        >
          {/* Previous messages display */}
          <div
            id="scrollableDiv"
            style={{
              height: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={chats?.data?.length || 0}
              next={getAllChats}
              hasMore={chats.hasMore}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              inverse={true}
              loader={<LinearProgress />}
              scrollableTarget="scrollableDiv"
            >
              <div ref={messagesEndRef} />
              {chats?.data?.map((chat, index) => {
                let prevMessageDate =
                  index > 0 && chats.data.length > index + 1
                    ? chats.data[index + 1].createdAt
                    : null;
                let currentMessageDate =
                  index > 0 ? chats.data[index].createdAt : null;

                // Determine if we need to show the date chip
                const showDateChip =
                  moment(prevMessageDate).format("DD/MMM/YYYY") !==
                  moment(currentMessageDate).format("DD/MMM/YYYY");
                return (
                  <>
                    <Stack
                      key={index}
                      direction="column"
                      spacing={1}
                      sx={{
                        width: { xs: "98%", lg: "80%" },
                        mb: 1,
                        ml:
                          chat.receiver === "Admin"
                            ? localStorage.getItem("i18nextLng") === "ar"
                              ? 0
                              : "auto"
                            : undefined,
                        mr:
                          chat.sender !== "user"
                            ? localStorage.getItem("i18nextLng") === "ar"
                              ? 0
                              : "auto"
                            : undefined,
                      }}
                    >
                      <Box
                        sx={{
                          color:
                            chat?.user?._id === userData?.data?.doc?._id
                              ? "#fff"
                              : "#6D6B6B",
                          fontSize: "12px",
                          fontWeight: "700",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {chat?.user?.fullname || ""}
                        <Box
                          sx={{
                            color: "#6D6B6B",
                            fontSize: "12px",
                            fontWeight: "700",
                            textAlign:
                              chat?.user?._id === userData?.data?.doc?._id
                                ? "right"
                                : "left",
                          }}
                        >
                          {new Date(chat.createdAt).toLocaleTimeString(
                            "en-GB",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          ) || "20:00"}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          background:
                            chat?.user?._id === userData?.data?.doc?._id
                              ? "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)"
                              : "#494130",
                          padding: { xs: "8px" },
                          lg: "8px 15px",
                          borderRadius:
                            chat?.user?._id === userData?.data?.doc?._id
                              ? "4px 4px 4px 0px"
                              : "4px 4px 0px 4px",
                          fontSize: "14px",
                          color:
                            chat?.user?._id === userData?.data?.doc?._id
                              ? "#000"
                              : "#fff",
                          width: chat.message.includes("media/chats/file")
                            ? "128px"
                            : "100%",
                        }}
                      >
                        {chat.message.includes("media/chats/file") ? (
                          isImageURL(chat.message) ? (
                            <ImagePreview
                              src={chat.message}
                              setPreview={setPreview}
                            />
                          ) : isVideoURL(chat.message) ? (
                            <VideoPreview
                              src={chat.message}
                              setPreview={setPreviewVideo}
                            />
                          ) : (
                            <div style={{ display: "contents" }}>
                              <Box
                                style={{
                                  display: "contents",
                                  cursor: "pointer",
                                }}
                              >
                                <span
                                  style={{
                                    width: "100%",
                                    height: "auto",
                                    fontSize: "large",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {chat.message.split(".")[1].toUpperCase()}
                                </span>
                              </Box>
                              {/* <PictureAsPdfSharp
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                        }}
                                      /> */}

                              <a
                                style={{ marginLeft: "auto" }}
                                href={`${global?.BASE_URL}${chat?.message}`}
                                target="_blank"
                                rel="noreferrer"
                                download
                              >
                                <ArrowCircleDown sx={{ fontSize: "1.2rem" }} />
                              </a>
                            </div>
                          )
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: convertMsgToLinks(chat.message),
                            }}
                          ></div>
                        )}
                      </Box>
                    </Stack>
                    {showDateChip && (
                      <Chip
                        style={{
                          margin: "auto",
                          color: "grey",
                          alignSelf: "center",
                          marginLeft: "40%",
                        }}
                        label={moment(chat.createdAt).format("DD/MMM/YYYY")}
                      />
                    )}
                  </>
                );
              })}
              {chats.isLoading && !lastTextDate && (
                <Box textAlign="center" fontWeight="bold">
                  Loading Chats...
                </Box>
              )}
            </InfiniteScroll>
          </div>
        </Box>
      )}
      {/* Message input area */}
      <Stack
        direction={{ xs: "row", lg: "row" }}
        spacing={{ xs: 2, lg: 0 }}
        alignItems="center"
        sx={{ mt: 0.5 }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{
            background: "#494130",
            p: 1,
            borderRadius: "5px",
            width: "100%",
            "& input": {
              background: "transparent",
              outline: "none",
              border: "0px",
              p: 1,
              color: "#fff",
              fontSize: "14px",
              height: "40px",
              "&::placeholder": {
                color: "#fff",
              },
            },
          }}
        >
          <IconButton
            size="small"
            sx={{ background: "#322E27", borderRadius: "5px" }}
          >
            {/* <SVG.Paper /> */}
            {!sendFile.file && (
              <Box
                sx={{ p: 0 }}
                disabled={
                  !selectedTicket ||
                  ["unapprove", "close", "archieve"].includes(
                    selectedTicket?.status
                  )
                }
              >
                <input
                  type="file"
                  id="select-file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setChatMessage({
                      ...chatMessage,
                      message: e.target.files[0].name,
                    });
                    setSendFile({ file: e.target.files[0], sending: false });
                  }}
                />

                <label htmlFor="select-file">
                  <AttachFile />
                </label>
              </Box>
            )}
            {sendFile.file && !sendFile.sending && (
              <Box
                onClick={() => {
                  setChatMessage((prev) => ({
                    ...prev,
                    message: "",
                  }));
                  setSendFile({ file: undefined, sending: false });
                }}
              >
                {/* <Close color="error" /> */}
              </Box>
            )}
            {sendFile.sending && (
              <Box>{/* <CircularProgress size={20} /> */}</Box>
            )}
            <Box
              onClick={handleArea}
              sx={{
                display: {
                  xs: "block",
                  lg: "none",
                },
              }}
            >
              {/* <OpenInFull /> */}
            </Box>
          </IconButton>
          <input
            ref={inputRef} // Attach ref to input field
            placeholder={
              selectedTicket?.data?.ticket &&
              selectedTicket?.data?.ticket?.archieve
                ? t("Ticket_message.messages.cannotChatArchived")
                : ["close", "disapprove", "unapprove"].includes(
                    selectedTicket?.data?.ticket?.status
                  )
                ? t("Ticket_message.messages.cannotChatClosed")
                : sendFile.sending
                ? t("Ticket_message.messages.uploadingFile")
                : t("Ticket_message.messages.typeHere")
            }
            disabled={
              sendFile.file ||
              !selectedTicket?.data?.ticket ||
              ["unapprove", "close", "archieve", "disapprove"].includes(
                selectedTicket?.data?.ticket?.status
              )
            }
            value={chatMessage.message}
            onChange={(e) =>
              setChatMessage({ ...chatMessage, message: e.target.value })
            }
            style={{ width: "100%" }}
            onKeyDown={enterKeySendText}
          />
          {matches ? (
            <IconButton
              sx={{
                background: "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
                borderRadius: "15px",
                width: "30px",
                height: "30px",
                ml:
                  localStorage.getItem("i18nextLng") === "ar"
                    ? "0px"
                    : "16px !important",
                mr:
                  localStorage.getItem("i18nextLng") === "ar"
                    ? "16px !important"
                    : "0px",
              }}
              onClick={handleSendMessage}
              disabled={chatMessage.message.length > 0 ? false : true}
            >
              <SVG.Send />
            </IconButton>
          ) : (
            ""
          )}
        </Stack>
        {matches ? (
          ""
        ) : (
          <IconButton
            sx={{
              background: "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
              borderRadius: "5px",
              width: "50px",
              height: "50px",
              ml:
                localStorage.getItem("i18nextLng") === "ar"
                  ? "0px"
                  : "16px !important",
              mr:
                localStorage.getItem("i18nextLng") === "ar"
                  ? "16px !important"
                  : "0px",
            }}
            onClick={handleSendMessage}
            disabled={chatMessage.message.length > 0 ? false : true}
          >
            <SVG.Send />
          </IconButton>
        )}
      </Stack>
      <ImagePreviewModal src={preview} setPreview={setPreview} />
      <VideoPreviewModal src={previewVideo} setPreview={setPreviewVideo} />
    </Box>
  );
}
